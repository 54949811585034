<template>
  <div class="title">
    <div class="titleLeft">
      <div>
        <img src="../../../assets/icon/icon/t01.png" alt="">
        <div class="text">{{ nameSite }}</div>
      </div>
    </div>
    <ul class="ulName">
      <li v-for="(item, index) in nameList" :key="index" @click="nameBtn(index)" :style="{
        'background': nameIndex == index ? 'linear-gradient(360deg, #3ddcfe, #76f1fb 38%, #ffffff)' : '',
        '-webkit-background-clip': nameIndex == index ? 'text' : 'none',
        'color': nameIndex == index ? 'transparent' : 'rgba(255, 255, 255, 0.80)'
      }"><i class="icon_i" v-if="nameIndex == index"><img src="../../../assets/icon/icon/bj01.png" alt=""></i>
        {{ item }}</li>
    </ul>
    <div class="titleRight">
      <div>
        <span>{{ showTime.time1 }}</span>
        <span>{{ showTime.time2 }}</span>
      </div>
      <div>{{ showTime.time3 }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'title',
  props: {
    nameSite: {
      type: String,
      default: function () {
        return 'ABB 智慧能源平台';
      },
    },
  },
  components: {},
  data() {
    return {
      showTime: {
        'time1': this.$moment().format('YYYY-MM-DD'),
        'time2': this.$moment().format('dddd'),
        'time3': this.$moment().format('LTS')
      },
      nameList: ['总览驾驶舱', '运维驾驶舱', '财务驾驶舱'],
      nameIndex: 2
    }
  },
  watch: {
  },
  computed: {

  },
  methods: {
    nameBtn(index) {
      this.nameIndex = index
    }

  },
  mounted() {
    setInterval(() => {
      this.showTime.time1 = this.$moment().format('YYYY-MM-DD')
      this.showTime.time2 = this.$moment().format('dddd')
      this.showTime.time3 = this.$moment().format('LTS')
    }, 1000)
  }
}
</script>
        
<style lang="less" scoped>
.title {
  width: 100%;
  height: 72px;
  position: relative;
  top: 0px;
  display: flex;
  color: #ffffff;
  justify-content: space-between;
  background-image: url(../../../assets/icon/icon/nav.png);
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;

  .titleLeft {
    height: 72px;
    display: flex;

    &>div {
      text-align: center;
      display: flex;
      align-items: center;

      &>img {
        display: block;
        width: 0.8rem;
        margin: 0 32px;
      }

      .text {
        width: 66%;
        height: 40px;
        background: linear-gradient(360deg, #3ddcfe, #76f1fb 38%, #ffffff);
        -webkit-background-clip: text;
        color: transparent;
        font-size: 22px;
        font-weight: 700;
        text-align: left;
        line-height: 40px;
        letter-spacing: 5px;
      }
    }
  }

  .titleRight {
    display: flex;
    align-items: center;

    &>div {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    &>div:nth-child(1) {
      width: 100px;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      text-align: left;
      color: #b4dcf1;
      line-height: 20px;
    }

    &>div:nth-child(2) {
      width: 166px;
      height: 56px;
      background: linear-gradient(180deg, #ffffff 17%, #b4dcf1 30%, #9dd8ff 75%);
      -webkit-background-clip: text;
      color: transparent;
      font-size: 40px;
      font-family: PingFang SC, PingFang SC-Semibold;
      font-weight: 600;
      text-align: center;
      margin: 0 81px 0 16px;

      line-height: 56px;
    }
  }
}

.ulName {
  position: absolute;
  left: 26%;
  bottom: 0px;
  display: flex;
  cursor: pointer;



  &>li {
    font-size: 20px;
    font-family: ABBvoice CNSG, ABBvoice CNSG-Regular;
    font-weight: 400;
    text-align: left;
    line-height: 25px;
    margin: 10px 30px;

    &>.icon_i {
      position: absolute;
      bottom: 0;
      margin-left: -36px;
      height: 80px;
      width: 200px;
    }
  }
}
</style>