<template>
  <div class="graphright" style="position: relative;">
    <div class="type-group" style="position: absolute;top: 31px;z-index: 999;">
      <!-- <span
        class="pick-type"
        v-for="(item, idx) in timetType"
        :key="idx"
        @click="changeType(item.code)"
      >
        <font :class="{ 'isactive-type': item.code == searchObj.TYPE }">{{
          item.name
        }}</font>
      </span> -->
    </div>
    <siteName :objname="objname"></siteName>
    <div ref="graphright" id="Energycost"></div>
  </div>
</template>
<script>
var echarts = require('echarts');
import "echarts/theme/macarons.js";
import siteName from "../../financeView/site";
export default {
  name: 'graphright',
  props: {},
  components: { siteName },
  data() {
    return {
      timetType: [
        {
          code: "按天",
          name: "按小时",
        },
        
        {
          code: "按月",
          name: "按天",
        },
        {
          code: "按年",
          name: "按月",
        },
      ],
      searchObj: {
        TYPE: "按天", //按天,按周,按月,按年
        VALUE: this.$moment(), //日期
      },
      detailInfo: {
        chart1: {},
      },
      objname: {
        name: 'Kilowatt Hour Cost',
        site: '度电成本'
      },
      newarr1:[]
    }
  },
  watch: {
    "currentTime": {
      handler: function (newVal, oldVal) {
        console.log(newVal)
        if (newVal) {
          this.getData();
        }
      },
      deep: true,
      immediate: true,
    },
    "currenttype": {
      handler: function (newVal, oldVal) {
        console.log(newVal)
        if (newVal) {
          this.searchObj.TYPE = newVal
          this.getData();
        }
      },
      deep: true,
      immediate: true,
    },
     "currentvule": {
      handler: function (newVal, oldVal) {
        console.log(newVal)
        if (newVal) {
          this.searchObj.VALUE = newVal
          this.getData();
        
        }
      },
      deep: true,
      immediate: true,
    },
    "currentSelectDeptInfo": {
      handler: function (newVal, oldVal) {
        if (oldVal) {
          this.getData();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    currentvule(){
      return this.$store.state.financevule;
    },
    currentTime() {
      return this.$store.state.timer;
    },
    currenttype(){
      return this.$store.state.financetype;
    },
    currentSelectDeptInfo() {
      return this.$store.state.overviewdept;
    },
  },
  methods: {
    transformSearch: function () {
      var TYPE = this.searchObj.TYPE;
      var dateStr = this.searchObj.VALUE.format("YYYY-MM-DD");
      var VALUE = this.$moment(dateStr);
      var monthVALUE = this.$moment(dateStr).startOf("month");
      var obj = {
        按天: {
          type: "day15m",
          starttime: VALUE.format("YYYY-MM-DD 00:00:00"),
          endtime: VALUE.format("YYYY-MM-DD 23:59:59"),
        },
        按周: {
          type: "week",
          starttime: VALUE.startOf("week").format("YYYY-MM-DD 00:00:00"),
          endtime: VALUE.endOf("week").format("YYYY-MM-DD 23:59:59"),
        },
        按月: {
          type: "month",
          starttime: monthVALUE.startOf("month").format("YYYY-MM-DD 00:00:00"),
          endtime: monthVALUE.endOf("month").format("YYYY-MM-DD 23:59:59"),
        },
        按年: {
          type: "year",
          starttime: VALUE.startOf("year").format("YYYY-MM"),
          endtime: VALUE.endOf("year").format("YYYY-MM"),
        },
      };
      return obj[TYPE];
    },
    transformSearch2: function () {
      var TYPE = this.searchObj.TYPE;
      var dateStr = this.searchObj.VALUE.format("YYYY-MM-DD");
      var VALUE = this.$moment(dateStr);
      var monthVALUE = this.$moment(dateStr).startOf("month");
      var obj = {
        按天: {
          type: "day15m",
          startTime: VALUE.format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.format("YYYY-MM-DD 23:59:59"),
        },
        按周: {
          type: "week",
          startTime: VALUE.startOf("week").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("week").format("YYYY-MM-DD 23:59:59"),
        },
        按月: {
          type: "month",
          startTime: monthVALUE.startOf("month").format("YYYY-MM-DD 00:00:00"),
          endTime: monthVALUE.endOf("month").format("YYYY-MM-DD 23:59:59"),
        },
        按年: {
          type: "year",
          startTime: VALUE.startOf("year").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("year").format("YYYY-MM-DD 23:59:59"),
        },
      };
      return obj[TYPE];
    },
    // 改变类型
    changeType(type) {
      this.searchObj.TYPE = type;
      this.getData();
    },
    getData() {
      let data = {
        deptCode: this.$store.state.overviewdept2,
      };
      Object.assign(data, this.transformSearch2());
      if (this.searchObj.TYPE == "按天") {
        data.type = "day15m";
        data.queryType = data.type;
        delete data.type;
      } else {
        data.queryType = data.type;
        delete data.type;
      }
      this.isLoading = true;
      this.$axios({
        url: Config.dev_url + "/api-apps-v2/api/v2/electric/price",
        method: "post",
        data,
      })
        .then((res) => {
          let result = res.data.data;
         
         console.log(result)
          var chart1 = {
            categoryData: result.categoryData,
            seriesData: result.seriesData,
          };
       
          this.detailInfo.chart1 = chart1;
       
          let newarr = [];
         
          // console.log(this.detailInfo.chart1.seriesData[0].value);
          this.detailInfo.chart1.seriesData[0].value.forEach((item) => {
            // console.log(item);
            // item.value.forEach((val) => {
            //   newarr.push(val);
            // });
            newarr.push(item);
          });
       
          this.initEchart();
      
        })
        .catch((wrong) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    initEchart() {
    
      
      var dom = document.getElementById('Energycost');
      let chartInit = echarts.init(dom);
      var option = {
        backgroundColor: 'rgba(0,0,0,0)',
        color: '',
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        // tooltip: {},
        grid: {
          top: '15%',
          bottom: '7%',
          left: '0%',
          right: '0%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          data: this.detailInfo.chart1.categoryData,
          axisLabel: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "white",
            fontSize: 14,
          },
        },
        yAxis: {
          name: "(元)",
          axisTick: {
            show: false,
          },
          nameTextStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "white",
            fontSize: 14,
            
          },
          axisLine: {
            lineStyle: {
              color: "white",
            },
          },
          axisLabel: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "white",
            fontSize: 14,
            formatter(v) {
              v = v.toString();
              if (v >= 100000000000) {
                return v.substring(0, 5) / 10 + "亿";
              } else if (v >= 10000000000) {
                return v.substring(0, 4) / 10 + "亿";
              } else if (v >= 1000000000) {
                return v.substring(0, 3) / 10 + "亿";
              } else if (v >= 100000000) {
                return v.substring(0, 2) / 10 + "亿";
              } else if (v >= 10000000) {
                return v.substring(0, 4) + "万";
              } else if (v >= 1000000) {
                return v.substring(0, 3) + "万";
              } else if (v >= 100000) {
                return v.substring(0, 2) + "万";
              } else if (v >= 10000) {
                return v.substring(0, 2) / 10 + "万";
              } else if (v >= 1000) {
                return v;
              } else if (v <= -100000000000) {
                return "-" + v.substring(1, 7) / 10 + "亿";
              } else if (v <= -100000000000) {
                return "-" + v.substring(1, 6) / 10 + "亿";
              } else if (v <= -10000000000) {
                return "-" + v.substring(1, 5) / 10 + "亿";
              } else if (v <= -1000000000) {
                return "-" + v.substring(1, 4) / 10 + "亿";
              } else if (v <= -100000000) {
                return "-" + v.substring(1, 3) / 10 + "亿";
              } else if (v <= -10000000) {
                return "-" + v.substring(1, 5) + "万";
              } else if (v <= -1000000) {
                return "-" + v.substring(1, 4) + "万";
              } else if (v <= -100000) {
                return "-" + v.substring(1, 3) + "万";
              } else if (v <= -10000) {
                return "-" + v.substring(1, 3) / 10 + "万";
              } else if (v <= -1000) {
                return v;
              } else {
                return v;
              }
            },
          },
          splitLine: {
                show: false,
                lineStyle: {
                    color: '#192a44'
                },
            },
        },
        series: [
          {
            data: this.detailInfo.chart1.seriesData[0].value,
            type: 'bar',
            barWidth:3,
            itemStyle: {
              
              color: {
                type: 'linear',
                x: 0,  //右
                y: 1,  //下
                x2: 0,  //左
                y2: 0,  //上
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(0,135,254,0)'
                  },
                  {
                    offset: 0.7,
                    color: 'rgba(79,172,254,1)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(79,172,254,1)' 
                  }
                ]
              }
            },
          }
        ]
      };
      setTimeout(() => { chartInit.resize() }, 200)
      chartInit.setOption(option);
      window.addEventListener("resize", function () {
        chartInit.resize()
      });
    }
  },
  mounted() {
    this.getData()
    // this.initEchart()
  }
}
</script>
                
<style lang="less" scoped>
.pick-type font {
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  color:rgba(255,255,255,0.7);
  line-height: 18px;
}
.type-group .pick-type .isactive-type{
  color:#DEFFFF;
}
.graphright {
  width: 100%;
  height: 100%;
  ul>li {
    width: 281px;
    height: 85px;
    display: flex;
    flex-direction: column;
    background-image: url(../../../../assets/icon/icon/lang01.png);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
  }
}

#Energycost {
  width: 100%;
  height: calc(100% - 38px);
}
</style>
                