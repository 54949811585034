<template>
  <div class="site">
    <div class="grouptitle">{{ objname.name }} <span>{{ objname.site }}</span></div>
    <img src="@/assets/imgs/big/xian.svg" style="width: 100%;height: 16px; height: 16px;" />
  </div>
</template>
<script>
export default {
  name: 'site',
  props: {
    objname: {
      type: Object,
      default: function () {
        return {
          name: '',
          site: ''
        };
      },
    },
  },
  components: {},
  data() {
    return {

    }
  },
  watch: {
  },
  computed: {

  },
  methods: {

  },
  mounted() {

  }
}
</script>
          
<style lang="less" scoped>
.site {
  display: flex;
  flex-direction: column;
  .grouptitle {
    color: white;
    font-size: 0.2rem;

    &>span {
      color: white !important;
      font-size: 18px ;
      margin-right: 10px !important;
    }
  }
}
</style>