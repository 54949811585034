<template>
  <div class="wrapperBox" ref="wrapperBox">
    <div
      class="bg-box"
      ref="bgBox"
      v-if="bgBox"
      :style="{
        background: `url(${backObj.url}) no-repeat left top`,
        'background-size': '100% 100%',
      }"
    ></div>
    <slot name="brother"></slot>
    <div class="wrapper" ref="wrapper">
      <slot></slot>
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script charset="utf-8">
export default {
  name: "FullscreenWrapper",
  props: {
    isFullModeProp: {
      //全屏参数
      type: [Boolean, String],
      default: false,
    },
    bgBox: {
      //内衬盒子是否需要显示
      type: Boolean,
      default: false,
    },
    borderHg: {
      //内衬盒子底边
      type: Number,
      default: 20,
    },
    backObj: {
      //内衬样式
      type: Object,
      default: () => {
        return {};
      },
    },
    wh: {
      //设计图宽度
      type: Number,
      default: 1920,
    },
    innerWrapper: {
      //页面内容的盒子ref名称，用来设置内衬盒子高度
      type: String,
      default: "innerWrapper",
    },
  },
  data() {
    return {
      isFullMode: false,
    };
  },
  watch: {
    isFullModeProp(val) {
      const elm = this.$refs.wrapperBox;
      if (val) {
        elm.requestFullscreen();
      } else {
        document.exitFullscreen();
      }
    },
  },
  mounted() {
    this.isFullMode = this.isFullModeProp;
    this.$nextTick(() => {
      this.initScreen();
    });
    window.addEventListener("resize", this.resize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resize);
  },
  methods: {
    initScreen() {
      const dome = this.$refs["wrapper"];

      if (dome.parentNode.offsetWidth < this.wh) {
        const scalenumber = dome.parentNode.offsetWidth / this.wh;
        sessionStorage.setItem("changeFlay", scalenumber); // smb 漏斗缩放使用
        // dome.style.transform = `scale(${scalenumber})`;
        if (this.bgBox) {
          this.$refs["bgBox"].style.height =
            this.$parent.$refs[this.innerWrapper].clientHeight * scalenumber +
            this.borderHg +
            "px";
        }
      } else {
        dome.style.width = "100%";
        sessionStorage.setItem("changeFlay", 1); // smb 漏斗缩放使用
        dome.style.transform = `scale(1)`;
        if (this.bgBox) {
          this.$refs["bgBox"].style.height =
            this.$parent.$refs[this.innerWrapper].clientHeight +
            this.borderHg +
            "px";
        }
      }
    },
    resize() {
      console.log(window.innerWidth, window.innerHeight, "dawwa");
      this.isFullMode = document.fullscreen;

      this.$nextTick(() => {
        this.initScreen();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.wrapperBox {
  position: relative;
  background: black;
  height: 100%;
  // display: flex;
  // flex-direction: column;
  .bg-box {
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
  }
}

.wrapper {
  // flex: 1;
  width: 100%;
  
  height: 100%;
  box-sizing: border-box;
  transform-origin: 0 0;
  position: absolute;
  left: 0;
  top: 0;
  & > div {
    
  }
}
</style>
