<template>
  <div class="workOverview">
    <div class="grouptitle">Daily Energy Consumption <span>当日用能</span></div>
    <img src="@/assets/imgs/big/xian.svg" style="width: 100%;
      height: 16px; height: 16px;" />
    <ul>
      <li v-for="(item, index) in listData" :key="index">
        <div><img :src="item.img" alt="" /></div>
        <div>
          <span style="font-size: 0.15rem;">{{ item.name }}<span style="font-size: 0.15rem;">{{ item.unit }}</span></span>
          <span style="font-size: 0.3rem;">
            {{ item.value }}
          </span>
        </div>
      </li>
    </ul>
  </div>
</template>
  
<script>

export default {
  name: "workOverview",
  components: {
  },
  props: {},
  watch: {
    "currentTime": {
      handler: function (newVal, oldVal) {
        console.log(newVal)
        
        if (newVal) {
          this.getData();
        }
      },
      deep: true,
      immediate: true,
    },
    "currenttype": {
      handler: function (newVal, oldVal) {
        console.log(newVal)
        this.searchObj.TYPE = newVal
        this.getData()
        this.getData2()
        this.getData3()
        // if (newVal) {
        //   this.searchObj.TYPE = newVal
        //   this.getList()
        //   this.getData()
        //   this.getData2()
        //   this.getData3()
        //   if(newVal=="按天"){
        //     this.gonglv = 142
        //   }
        //   if(newVal=="按月"){
        //     this.gonglv = 3012
        //   }
        //   if(newVal=="按年"){
        //     this.gonglv = 24625
        //   }
        // }
      },
      deep: true,
      immediate: true,
    },
    "currentvule": {
      handler: function (newVal, oldVal) {
        console.log(newVal)
        if (newVal) {
          this.searchObj.VALUE = newVal
          this.getData()
          this.getData2()
          this.getData3()
        }
      },
      deep: true,
      immediate: true,
    },
    "currentSelectDeptInfo": {
      handler: function (newVal, oldVal) {
        if (oldVal) {
          this.getList()
          this.getData()
          this.getData2()
          this.getData3()
        }
      },
      deep: true,
      immediate: true,
    },
    "bigdeptcode": {
      handler: function () {
        this.getData();
      },
      deep: true,
      immediate: true,
    },
    "changeValue": {
      handler: function () {
        this.getData();
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      
      operateWidth: 64, //操作列宽度
      isLoading: false, //数据加载中
      timetType: [
        {
          code: "按天",
          name: "按小时",
        },
        
        {
          code: "按月",
          name: "按天",
        },
        {
          code: "按年",
          name: "按月",
        },
      ],
      searchObj: {
        TYPE: "按天", //按天,按周,按月,按年
        VALUE: this.$moment(), //日期
      },
      detailInfo: {
        chart1: {},
      },
      energy:"",
      maindata:"",
      cost:"",
      gonglv:"142"
    };
  },
  computed: {
    currentTime() {
      return this.$store.state.timer;
    },
    currenttype(){
      return this.$store.state.financetype;
    },
    currentvule(){
      return this.$store.state.financevule;
    },
    currentSelectDeptInfo() {
      return this.$store.state.overviewdept2;
    },
    listData(){
      let arr = [
        {
          name: '当前能耗',
          unit:"(kWh)",
          value: this.toThousandsSeparator(this.energy),
          img: require('@/assets/imgs/big/icon20.png'),
          site: '个'
        },
        {
          name: '当前功率',
          unit:"(kW)",
          value: this.gonglv,
          img: require('@/assets/imgs/big/icon12.png'),
          site: '个'
        },
        {
          name: '日发电量',
           unit:"(kWh)",
          value: this.toThousandsSeparator(this.maindata),
          img: require('@/assets/imgs/big/icon8.png'),
          site: '套'
        },
        {
          name: '当日能源收益',
           unit:"(元)",
          value: this.toThousandsSeparator(this.cost),
          img: require('@/assets/imgs/big/icon3.png'),
          site: '套'
        },
      ]
      return arr
    },
    // 当前Dept信息
    bigdeptcode() {
      return this.$store.state.bigdeptcode;
    },
  },
  methods: {
    toThousandsSeparator: function (value) {
      if (!value) return 0;
      // 获取整数部分
      const intPart = Math.trunc(value);
      // 整数部分处理，增加,
      const intPartFormat = intPart
        .toString()
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
      // 预定义小数部分
      let floatPart = "";
      // 将数值截取为小数部分和整数部分
      const valueArray = value.toString().split(".");
      if (valueArray.length === 2) {
        // 有小数部分
        floatPart = valueArray[1].toString(); // 取得小数部分
        return intPartFormat + "." + floatPart;
      }
      return intPartFormat + floatPart;
    },
    transformSearch: function () {
      var TYPE = this.searchObj.TYPE;
      var dateStr = this.searchObj.VALUE.format("YYYY-MM-DD");
      var VALUE = this.$moment(dateStr);
      var monthVALUE = this.$moment(dateStr).startOf("month");
      var obj = {
        按天: {
          type: "day1h",
          startTime: VALUE.format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.format("YYYY-MM-DD 23:59:59"),
        },
        按周: {
          type: "week",
          startTime: VALUE.startOf("week").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("week").format("YYYY-MM-DD 23:59:59"),
        },
        按月: {
          type: "month",
          startTime: monthVALUE.startOf("month").format("YYYY-MM-DD 00:00:00"),
          endTime: monthVALUE.endOf("month").format("YYYY-MM-DD 23:59:59"),
        },
        按年: {
          type: "year",
          startTime: VALUE.startOf("year").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("year").format("YYYY-MM-DD 23:59:59"),
        },
      };
      return obj[TYPE];
    },
    transformSearch2: function () {
      var TYPE = this.searchObj.TYPE;
      var dateStr = this.searchObj.VALUE.format("YYYY-MM-DD");
      var VALUE = this.$moment(dateStr);
      var monthVALUE = this.$moment(dateStr).startOf("month");
      var obj = {
        按天: {
          type: "day1h",
          startTime: VALUE.format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.format("YYYY-MM-DD 23:59:59"),
        },
        按周: {
          type: "week",
          startTime: VALUE.startOf("week").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("week").format("YYYY-MM-DD 23:59:59"),
        },
        按月: {
          type: "month",
          startTime: monthVALUE.startOf("month").format("YYYY-MM-DD 00:00:00"),
          endTime: monthVALUE.endOf("month").format("YYYY-MM-DD 23:59:59"),
        },
        按年: {
          type: "year",
          startTime: VALUE.startOf("year").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("year").format("YYYY-MM-DD 23:59:59"),
        },
      };
      return obj[TYPE];
    },
    transformSearch3: function () {
      var TYPE = this.searchObj.TYPE;
      var dateStr = this.searchObj.VALUE.format("YYYY-MM-DD");
      var VALUE = this.$moment(dateStr);
      var monthVALUE = this.$moment(dateStr).startOf("month");
      var obj = {
        按天: {
          type: "day1h",
          startTime: VALUE.format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.format("YYYY-MM-DD 23:59:59"),
        },
        按周: {
          type: "week",
          startTime: VALUE.startOf("week").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("week").format("YYYY-MM-DD 23:59:59"),
        },
        按月: {
          type: "month",
          startTime: monthVALUE.startOf("month").format("YYYY-MM-DD 00:00:00"),
          endTime: monthVALUE.endOf("month").format("YYYY-MM-DD 23:59:59"),
        },
        按年: {
          type: "year",
          startTime: VALUE.startOf("year").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("year").format("YYYY-MM-DD 23:59:59"),
        },
      };
      return obj[TYPE];
    },
    getData() {
      let data = {
        deptCode: this.$store.state.overviewdept2,
        equipment: this.$store.state.overviewdept2,
      };
      Object.assign(data, this.transformSearch());
      data.queryType = data.type;
      delete data.type;
      data.type = this.searchObj.checked ? 1 : 0;
      this.isLoading = true;
      this.year = data.startTime.split("-")[0];
      this.$axios({
        url: Config.dev_url + "/api-apps-v2/api/v2/energy/total",
        method: "post",
        data,
      })
        .then((res) => {
          let result = res.data.data;
          this.energy = result.totalPower
          console.log(result)
        })
        .catch((wrong) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    getData2() {
      // if(this.searchObj.ITEM == ""){
      //   this.detailInfo = {}
      //   var chart1 = {
      //     categoryData: [],
      //     seriesData: []
      //   }
      //   this.detailInfo.chart1 = chart1
      //   this.initEchart()
      //   return
      // }
      //   let data = {
      //     "deptCode": this.currentSelectDeptInfo.CODE,
      //     "assetNumber":this.searchObj.ITEM,
      //     "check": this.searchObj.TYPE1
      //   }
      let data = {
        // deptCode:"221027091",
        deptCode: "221027631",
        check: '总发电量',
      };
      Object.assign(data, this.transformSearch3());
      data.queryType = data.type;
      delete data.type;
      console.log(data);
      this.detailInfo = {};
      this.isLoading = true;
      this.$axios({
        url: Config.dev_url + "/api-apps-v2/api/v2/photovoltaic/generation/ranking",
        method: "post",
        data,
      })
        .then((res) => {
        
          this.maindata = parseInt(res.data.data.seriesData[0].value[0])
          
        })
        .catch((wrong) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    getData3() {
      
      let data = {
        deptCode:this.$store.state.overviewdept2,
      };
      Object.assign(data, this.transformSearch2());
      
      data.queryType = data.type;
      delete data.type;
      this.$axios({
        url: Config.dev_url + "/api-apps-v2/api/v2/electric/income",
        method: "post",
        data,
      })
        .then((res) => {
          this.detailInfo = {};
          
          let result = res.data.data;
          console.log(result);
          var chart1 = {
            categoryData: result.categonyData,
            seriesData: result.seriesData,
          };
          
          let newarr = [];
          this.detailInfo.chart1 = chart1;
          this.detailInfo.self = result.self.toString();
          
          this.detailInfo.surplus = result.surplus.toString();

          this.detailInfo.storage = result.storage.toString();
          this.cost = parseInt(this.detailInfo.self)+parseInt(this.detailInfo.surplus)+parseInt(this.detailInfo.storage)
          console.log(parseInt(-2))
        })
        .catch((wrong) => {})
        .finally(() => {
          
        });
    },
    getList() {
      this.$axios({
        url: Config.dev_url + "/api-apps-v2/api/v2/equipment/assetlist",
        method: "post",
        data: {
          deptCode: this.$store.state.overviewdept2,
          type:"电表"
        },
      }).then((res) => {
        let asset = []
        for(let item of res.data.data){
          asset.push(item.assetNumber)
        }
        this.asset = asset
        this.getData4();
      });
    },
    getData4() {
      this.detailInfo = {};
      let data = {
        "assetNum": this.asset,
        "variableName": [
          'Avg Total active power',
        ]
      }
      
      let url = Config.dev_url + "/api-apps-v2/api/v2/equipment/variable/real/value";
      this.$axios.post(url, data).then((res) => {
        let allData = res.data.data
        
        console.log(res.data, '')
      });
    },
  },
  mounted() {
    this.getList()
    this.getData()
    this.getData2()
    this.getData3()
  },
};
</script>
  
<style scoped lang="less">
.grouptitle {
  color: white;
  font-size: 0.2rem;

  &>span {
    color: white !important;
    font-size: 18px ;
    margin-right: 10px !important;
  }
}

.workOverview {
  // width: 100%;
 

  ul {
    color: #fff;
    width: 100%;
    height: calc(100% - 35px);
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    &>li {
      display: flex;
      align-items: center;
      height: 0.7rem;
      &>div {

        &>img {
          display: inline-block;
          width: 0.6rem;
          height: 0.6rem;
          
        }
      }

      // &>div:nth-child(1) {
      //   width: 0.6rem;
      //   height: 0.6rem;
      // }

      &>div:nth-child(2) {
        margin-left: 14px;
        flex: 1;
        display: flex;
        flex-direction: column;

        &>span {
          flex: 1;
          display: inline-flex;
        }

        &>span:nth-child(1) {
          margin: 10px 0;
          font-size:  0.2rem;
          line-height: 0.2rem;
          color: rgba(243,243,243,0.8);
        }

        &>span:nth-child(2) {
          font-size: 0.2rem;
          line-height: 0.3rem;

          &>p {
            font-size: 0.17rem;
            margin-left: 0.5rem;
          }
        }
      }
    }
  }
}
</style>