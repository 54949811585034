<template>
    <div style="height: calc(100% - 75px);position: relative;left: -1px;">
      <div class="overview" style="position: absolute;right: 0px;z-index: 99;">
        <ul>
          <li v-for="(item, index) in tabList" :key="index" @click="btnSite(index,item)" :style="{ 'background': indexSite == index ? '#000000' : 'none', 'opacity': indexSite == index ? 1 : 0.65,'border':indexSite == index ?'1px #3e6db0 solid':'black' }">
            {{ item }}
          </li>
            <a-date-picker
              style="margin-top: 10px;margin-right:20px;margin-left:20px;"
              v-if="searchObj.TYPE == '按天'"
              placeholder="选天"
              v-model="searchObj.VALUE"
              @change="onChange"
            />
            <a-month-picker
              style="margin-top: 10px;margin-right:20px;margin-left:20px;"
              v-if="searchObj.TYPE == '按月'"
              placeholder="选月"
              v-model="searchObj.VALUE"
              @change="onChange"
            />
            <a-date-picker
              style="margin-top: 10px;margin-right:20px;margin-left:20px;"
              v-if="searchObj.TYPE == '按年'"
              placeholder="选年"
              v-model="searchObj.VALUE"
              @change="onChange"
              mode="year"
              :open="panelOpen"
              @openChange="openChange"
              @panelChange="panelChange"
              format="YYYY"
            />
        </ul>
      </div>
      <overview v-if="nameIndex==0" style="    padding-top: 40px;"></overview>
      <energy v-if="nameIndex==2" style="    padding-top: 25px;"></energy>
      <emission v-if="nameIndex==1" style="    padding-top: 25px;"></emission>
      
    </div>
</template>

<script>
import overview from "@/views/bigscreen2/overview/overview";
import energy from "@/views/bigscreen/overview/energy";
import emission from "@/views/bigscreen/overview/emission";
export default {
  created() {

  },
  data() {
    return {
      panelOpen: false,
      searchObj: {
        TYPE: "按天", //按天,按周,按月,按年
        VALUE: this.$moment(), //日期
      },
      tabList: ['按天', '按月', '按年'],
      tabList2: [ '','本月', '本年'],
      nameList: [{
        name:"总览",
        en:"Overview"
      },{
        name:"排放分析",
        en:"Emission Analysis"
      },{
        name:"能源分析",
        en:"Energy Analysis"
      }],
      nameIndex: 0,
      nameSite: 'ABB 智慧能源平台',
      isFullMode: false,
    }
  },
  components: {
    overview,
    energy,
    emission
  },
  computed: {
    indexSite(){
      let index = 0
      if(this.$store.state.financetype == '按天'){
        index = 0
      }else if(this.$store.state.financetype == '按月'){
        index = 1
      }else if(this.$store.state.financetype == '按年'){
        index =2
      }
      return index
    },
  },
  methods: {
    openChange(status) {
      this.panelOpen = status;
    },
    // 年份弹窗关闭
    panelChange(value) {
      this.searchObj.VALUE = value;
      this.panelOpen = false;
      this.$store.commit("setfinancevule", this.searchObj.VALUE)
    },
    onChange(){
      this.$store.commit("setfinancevule", this.searchObj.VALUE)
    },
    btnSite(index,item) {
      console.log(index)
      if(item == "按天"){
        this.searchObj.TYPE = "按天"
        this.$store.commit("setfinancetype", "按天")
        this.$store.commit("setfinancevule", this.searchObj.VALUE)
      }else if(item =="按月"){
        this.searchObj.TYPE = "按月"
        this.$store.commit("setfinancetype", "按月")
        this.$store.commit("setfinancevule", this.searchObj.VALUE)
      }else if(item =="按年"){
        this.searchObj.TYPE = "按年"
        this.$store.commit("setfinancetype", "按年")
        this.$store.commit("setfinancevule", this.searchObj.VALUE)
      }
      
    },
    nameBtn(index) {
      this.nameIndex = index
    },
  },
  mounted() {
   
  }
}
</script>

<style lang="less" scoped>
.ulName {
  bottom: 0px;
  display: flex;
  cursor: pointer;
  &>li {
    font-size: 20px;
    font-family: ABBvoice CNSG, ABBvoice CNSG-Regular;
    font-weight: 400;
    text-align: left;
    line-height: 25px;
    margin: 10px 30px;

    &>.icon_i {
      position: absolute;
      bottom: 0;
      margin-left: -36px;
      height: 37px;
      width: 106px;

    }
  }
}
.title {
  width: 100%;
  height: 72px;
  position: absolute;
  bottom: 0px;
  display: flex;
  color: #ffffff;
  justify-content: center;
  background-image: url(../../../assets/imgs/big/icon2.png);
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;

  .titleLeft {
    height: 72px;
    display: flex;

    &>div {
      text-align: center;
      display: flex;
      align-items: center;

      &>img {
        display: block;
        width: 0.8rem;
        margin: 0 32px;
      }

      .text {
        width: 333px;
        height: 40px;
        background: linear-gradient(360deg, #3ddcfe, #76f1fb 38%, #ffffff);
        -webkit-background-clip: text;
        color: transparent;
        font-size: 0.26rem;
        font-weight: 700;
        text-align: left;
        line-height: 40px;
        letter-spacing: 5px;
      }
    }
  }

  .titleRight {
    display: flex;
    align-items: center;

    &>div {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    &>div:nth-child(1) {
      width: 100px;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      text-align: left;
      color: #b4dcf1;
      line-height: 20px;
    }

    &>div:nth-child(2) {
      width: 172px;
      height: 56px;
      background: linear-gradient(180deg, #ffffff 17%, #b4dcf1 30%, #9dd8ff 75%);
      -webkit-background-clip: text;
      color: transparent;
      font-size: 40px;
      font-family: PingFang SC, PingFang SC-Semibold;
      font-weight: 600;
      text-align: center;
      margin: 0 81px 0 16px;

      line-height: 56px;
    }
  }
}
.overview{
  ul {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 6px;

  li {
    font-size: 16px;
    font-family: PingFang SC, PingFang SC-Semibold;
    font-weight: 600;
    text-align: right;
    color: #ffffff;
    line-height: 12px;
    padding: 12px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0 3px;
    margin-top: 5px;
  }
}
}
</style>