<template>
  <div class="graphright" style="position: relative;">
    <!-- <div class="type-group" style="position: absolute;top: 31px;z-index: 999;">
      <span
        class="pick-type"
        v-for="(item, idx) in timetType"
        :key="idx"
        @click="changeType(item.code)"
      >
        <font :class="{ 'isactive-type': item.code == searchObj.TYPE }">{{
          item.name
        }}</font>
      </span>
    </div> -->
    <siteName :objname="objname"></siteName>
    <div ref="graphright" id="Information"></div>
  </div>
</template>
<script>
var echarts = require('echarts');
import "echarts/theme/macarons.js";
import siteName from "../../financeView/site";
export default {
  name: 'graphright',
  props: {},
  components: { siteName },
  data() {
    return {
      timetType: [
        {
          code: "按天",
          name: "按小时",
        },
        
        {
          code: "按月",
          name: "按天",
        },
        {
          code: "按年",
          name: "按月",
        },
      ],
      searchObj: {
        TYPE: "按天", //按天,按周,按月,按年
        VALUE: this.$moment(), //日期
      },
      detailInfo: {
        chart1: {},
      },
      objname: {
        name: 'Energy Income',
        site: '能源收益'
      },
      newarr1:[]
    }
  },
  watch: {
    "currentTime": {
      handler: function (newVal, oldVal) {
        console.log(newVal)
        if (newVal) {
          this.getData();
        }
      },
      deep: true,
      immediate: true,
    },
    "currenttype": {
      handler: function (newVal, oldVal) {
        console.log(newVal)
        if (newVal) {
          this.searchObj.TYPE = newVal
          this.getData();
        }
      },
      deep: true,
      immediate: true,
    },
    "currentvule": {
      handler: function (newVal, oldVal) {
        console.log(newVal)
        if (newVal) {
          this.searchObj.VALUE = newVal
          this.getData();
     
        }
      },
      deep: true,
      immediate: true,
    },
    "currentSelectDeptInfo": {
      handler: function (newVal, oldVal) {
        if (oldVal) {
          this.getData();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    currentTime() {
      return this.$store.state.timer;
    },
    currenttype(){
      return this.$store.state.financetype;
    },
    currentvule(){
      return this.$store.state.financevule;
    },
    currentSelectDeptInfo() {
      return this.$store.state.overviewdept2;
    },
  },
  methods: {
    transformSearch: function () {
      var TYPE = this.searchObj.TYPE;
      var dateStr = this.searchObj.VALUE.format("YYYY-MM-DD");
      var VALUE = this.$moment(dateStr);
      var monthVALUE = this.$moment(dateStr).startOf("month");
      var obj = {
        按天: {
          type: "day1h",
          startTime: VALUE.format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.format("YYYY-MM-DD 23:59:59"),
        },
        按周: {
          type: "week",
          startTime: VALUE.startOf("week").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("week").format("YYYY-MM-DD 23:59:59"),
        },
        按月: {
          type: "month",
          startTime: monthVALUE.startOf("month").format("YYYY-MM-DD 00:00:00"),
          endTime: monthVALUE.endOf("month").format("YYYY-MM-DD 23:59:59"),
        },
        按年: {
          type: "year",
          startTime: VALUE.startOf("year").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("year").format("YYYY-MM-DD 23:59:59"),
        },
      };
      return obj[TYPE];
    },
    // 改变类型
    changeType(type) {
      this.searchObj.TYPE = type;
      this.getData();
    },
    getData() {
      let data = {
        deptCode: this.$store.state.overviewdept2,
      };
      Object.assign(data, this.transformSearch());
      data.queryType = data.type;
      delete data.type;
      this.isLoading = true;
      this.$axios({
        // url: Config.dev_url + "/api-apps/ectricity/electric/income",
        url: Config.dev_url + "/api-apps-v2/api/v2/electric/income",
        method: "post",
        data,
      })
        .then((res) => {
          
          this.detailInfo = {};
          let result = res.data.data;
          console.log(result);
        
          var chart1 = {
            categoryData: result.categoryData,
            seriesData: result.seriesData,
          };
          let newarr = [];
        
          this.detailInfo.chart1 = chart1;
          this.detailInfo.self = result.self.toString();
          this.detailInfo.surplus = result.surplus.toString();
          
          this.detailInfo.storage = result.storage.toString();
          let arr = ['自发自用','余电上网','储能收益',];
          this.newarr1 = []
          for (const i in this.detailInfo) {
            if (this.detailInfo.hasOwnProperty.call(this.detailInfo, i)) {
              const element = this.detailInfo[i];
              console.log(element);
              if (typeof element == "string") {
                if (element >= 100000000000) {
                  this.newarr1.push({ value: element.substring(0, 5) / 10, unit: "亿" });
                } else if (element >= 10000000000) {
                  this.newarr1.push({ value: element.substring(0, 4) / 10, unit: "亿" });
                } else if (element >= 1000000000) {
                  this.newarr1.push({ value: element.substring(0, 3) / 10, unit: "亿" });
                } else if (element >= 100000000) {
                  this.newarr1.push({ value: element.substring(0, 2) / 10, unit: "亿" });
                } else if (element >= 10000000) {
                  this.newarr1.push({ value: element.substring(0, 4), unit: "万" });
                } else if (element >= 1000000) {
                  this.newarr1.push({ value: element.substring(0, 5)/ 100, unit: "万" });
                } else if (element >= 100000) {
                  this.newarr1.push({ value: element.substring(0, 4)/ 100, unit: "万" });
                } else if (element >= 10000) {
                  this.newarr1.push({ value: element.substring(0, 3) / 100, unit: "万" });
                } else if (element >= 1000) {
                  this.newarr1.push({ value: element, unit: "元" })
                } else if (element <= -100000000000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 7) / 10, unit: "亿" });
                } else if (element <= -100000000000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 6) / 10, unit: "亿" });
                } else if (element <= -10000000000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 5) / 10, unit: "亿" });
                } else if (element <= -1000000000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 4) / 10, unit: "亿" });
                } else if (element <= -100000000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 3) / 10, unit: "亿" });
                } else if (element <= -10000000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 5), unit: "万" });
                } else if (element <= -1000000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 4), unit: "万" });
                } else if (element <= -100000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 3), unit: "万" });
                } else if (element <= -10000) {
                  this.newarr1.push({ value:"-" + element.substring(1,3) / 10, unit: "万" });
                } else if (element <= -1000) {
                  this.newarr1.push({ value:element, unit: "元" });
                } else {
                  this.newarr1.push({ value:element, unit: "元" });
                }
              }
            }
          }
        
          this.newarr1.forEach((item,index)=>{
            item['name'] = arr[index]
          })
          this.detailInfo.chart1.seriesData.forEach((item) => {
            item.value.forEach((val) => {
              newarr.push(val);
            });
          });
          
          this.initEchart();
        })
        .catch((wrong) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    initEchart() {
      console.log(this.detailInfo.chart1)
      var dom = document.getElementById('Information');
      let chartInit = echarts.init(dom);
      var option = {
        backgroundColor: 'rgba(0,0,0,0)',
        // tooltip: {},
        grid: {
          top: '15%',
          bottom: '7%',
          left: '0%',
          right: '0%',
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        legend: {
          right: 0,
          top: "1%",
          icon: "rect", //图例形状
          itemWidth: 8,
          itemHeight: 8,
          textStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "white",
          },
        },
        xAxis: [
          {
            type: 'category',
            data: this.detailInfo.chart1.categoryData,
            axisPointer: {
              type: 'shadow'
            },
            axisLabel: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "white",
            fontSize: 14,
          },
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '(元)',
            splitLine: {
                show: false,
                lineStyle: {
                    color: '#192a44'
                },
            },
            axisLabel: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "white",
              fontSize: 12,
            },
            axisLabel: {
              fontFamily: "ABBvoice_WCNSG_Rg",
               color: "white",
              fontSize: 12,
            },
            nameTextStyle: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "white",
              fontSize: 12,
            },
            // min: 0,
            // max: 250,
            // interval: 50,
          },
          
        ],
        series: [
          {
            name: '自发自用',
            type: 'line',
            stack: '总量',
            showSymbol:false,
            areaStyle: {
              color: '#4FACFE'
            },
            lineStyle: {
              color: '#4FACFE'
            },
            
            data: this.detailInfo.chart1.seriesData[0].value
          },
          {
            name: '余电上网',
            type: 'line',
            stack: '总量',
            areaStyle: {
              color: '#69FCBD'
            },
            lineStyle: {
              color: '#69FCBD'
            },
            showSymbol:false,
            
            data: this.detailInfo.chart1.seriesData[1].value
          },
          {
            name: '储能收益',
            type: 'line',
            showSymbol:false,
            stack: '总量',
            areaStyle: {
              color: '#E98327'
            },
            lineStyle: {
              color: '#E98327'
            },
            
            data: this.detailInfo.chart1.seriesData[2].value
          }
        ]
      };
      setTimeout(() => { chartInit.resize() }, 200)
      chartInit.setOption(option);
      window.addEventListener("resize", function () {
        chartInit.resize()
      });
    }
  },
  mounted() {
    this.getData()
    // this.initEchart()
  }
}
</script>
                
<style lang="less" scoped>
.pick-type font {
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  color:rgba(255,255,255,0.7);
  line-height: 18px;
}
.type-group .pick-type .isactive-type{
  color:#DEFFFF;
}
.graphright {
  width: 100%;
  height: 100%;
  ul>li {
    width: 281px;
    height: 85px;
    display: flex;
    flex-direction: column;
    background-image: url(../../../../assets/icon/icon/lang01.png);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
  }
}

#Information {
  width: 100%;
  height: calc(100% - 38px);
}
</style>
                